<template>
  <div class="userinfo-orderList">
    <div class="notice-list">
      <div
        class="notice"
        v-for="(item, i) in noticeList"
        :key="i"
        @click="toDetail(item)"
      >
        <div class="notice-title">{{ item.Title }}</div>
        <div class="date">{{ item.CreatedAt }}</div>
      </div>
    </div>
    <div class="empty-box flex-column-center" v-if="noticeList.length === 0">
      <img :src="emptyUrl" alt="" />
      <div class="">{{ $t('zan-wu-shu-ju') }}</div>
    </div>
    <div class="flex-center user-pager" v-if="noticeList.length > 0">
      <el-pagination
        background
        @current-change="currentChange"
        :current-page="page.current"
        :page-size="page.size"
        layout=" prev, pager,  next"
        :total="page.total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { getNoticeList } from '@/api/index'
export default {
  components: {},
  data() {
    return {
      emptyUrl: require('@/assets/imgs/emtpy.svg'),
      noticeList: [],
      page: {
        size: 10,
        current: 1,
        total: 1
      }
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      getNoticeList({
        current: this.page.current,
        pageSize: this.page.size
      }).then((res) => {
        this.noticeList = res.data.Items
        this.page.total = res.data.Pagination.totalRecords
      })
    },
    changeTab(i) {
      this.tabIndex = i
    },
    currentChange(page) {
      this.page.current = page
      this.init()
    },
    toDetail(data) {
      sessionStorage.setItem('noticeDetail', JSON.stringify(data))
      this.$router.push({
        name: 'userInfoNoticeDetail'
      })
    }
  }
}
</script>